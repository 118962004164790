<template>
<div class="tabs">
  <!--  v-for="tab in tabs" -->
  <button :class="['tabs__item', {'tabs__item--active': idx === active}]"
    @click.prevent="toggle(idx)"
    v-for="(tab, idx) in tabs" :key="tab">
    {{tab}}
  </button>
</div>
</template>

<script>
export default {
  props: ['tabs', 'active'],
  methods: {
    toggle(idx) {
      this.$emit('switchTab', idx)
    },
  },
}
</script>

<style lang="scss">
.tabs {
  display: flex;
  &__item {
    flex: 1 0 50%;
    max-width: 50%;
    height: 40px;
    margin: 0;
    padding: 0;
    font: 15px/18px 'ProximaNovaRegular', sans-serif;
    text-align: center;
    color: #FFFFFF;
    background-color: #272C30;
    border-radius: 3px;
    border: none;
    cursor: pointer;
    outline: none;
    &--active {
      cursor: default;
      background-color: #C70552;
      color: #FFFFFF;
    }
  }
}
</style>
