<template>
  <div class="projection">
    <p>
      <input
        type="radio"
        id="outside"
        value="outside"
        :checked="house === 'outside'"
        @change="setProjection('outside')"
        name="radio-group"
      />
      <label for="outside">{{ i18n.VIEW_OUTSIDE }}</label>
    </p>
    <p>
      <input
        type="radio"
        id="inside"
        value="inside"
        :checked="house === 'inside'"
        @change="setProjection('inside')"
        name="radio-group"
      />
      <label for="inside">{{ i18n.VIEW_INSIDE }}</label>
    </p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('config', ['house']),
    ...mapGetters('session', ['i18n'])
  },
  methods: {
    setProjection(value) {
      this.$store.dispatch('config/CONFIG_SET_HOUSE_VIEW', value);
    }
  }
};
</script>

<style lang="css">
.projection {
  margin-left: 30px;
}
[type='radio']:checked,
[type='radio']:not(:checked) {
  position: absolute;
  left: -9999px;
}
[type='radio']:checked + label,
[type='radio']:not(:checked) + label {
  position: relative;
  padding: 4px 20px 0 40px;
  cursor: pointer;
  font: 15px/18px 'ProximaNovaRegular', sans-serif;
  color: #ffffff;
  display: flex;
}
[type='radio']:checked + label:before,
[type='radio']:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 24px;
  height: 24px;
  border: 1px solid #7d8b97;
  border-radius: 100px;
}
[type='radio']:checked + label:after,
[type='radio']:not(:checked) + label:after {
  content: '';
  width: 10px;
  height: 10px;
  background: #c70552;
  position: absolute;
  top: 8px;
  left: 8px;
  border-radius: 100px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
[type='radio']:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
</style>
