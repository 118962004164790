<template>
  <div class="overlay">
    <page-title>{{ i18n.MENU.colour }}</page-title>
    <div class="page-width">
      <projection />
      <hr class="colors__hr" />
      <div class="checkform" :key="house">
        <p>
          <input
            type="checkbox"
            :checked="showBlackChecked"
            @change="toggleBlack"
            id="black_design"
          />
          <label for="black_design">{{ i18n.COLOUR.BLACK }}</label>
        </p>
      </div>
      <tabset
        :tabs="[...i18n.COLOUR.TABS]"
        style="margin-top: 20px;"
        @switchTab="toggleTab"
        :active="activeTab"
      />
      <div class="colors">
        <div class="color__empty" v-if="items.length === 0">
          {{ i18n.COLOUR.NO_COLORS }}
        </div>
        <label
          :class="[
            'colors__item',
            { 'colors__item--current': item.id === activeColor.id },
            { 'colors__item--active': item.active === '1' }
          ]"
          v-for="(item, idx) in items"
          @click.prevent="
            setColor(item, item.id === activeColor.id || item.active === '0')
          "
          :key="`color__${idx}`"
        >
          <span
            v-if="item.pattern_svg === ''"
            class="colors__bg"
            :style="`background-color: ${item.colour}`"
          >
            <div
              class="colors__title"
              :style="item.color_text ? `color: ${item.color_text}` : ''"
            >
              {{ item.colour_name }}
              <small>{{ item.title }}</small>
            </div>
          </span>
          <span
            v-else
            class="colors__bg"
            :style="`background-image: url(${item.pattern_image})`"
          >
            <div
              class="colors__title"
              :style="item.color_text ? `color: ${item.color_text}` : ''"
            >
              {{ item.colour_name }}
              <small>{{ item.title }}</small>
            </div>
          </span>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import PageTitle from '@/components/page-title/page-title.vue';
import Projection from '@/components/projection/projection.vue';
import Tabset from '@/components/shared/tabset.vue';
import axios from 'axios';
import { mapGetters } from 'vuex';
import { API } from '@/constants/api';
import { setCode } from '@/utils/code';

export default {
  data() {
    return {
      white: {},
      activeTab: 0,
      colorType: 'complete',
      items: []
    };
  },
  components: {
    PageTitle,
    Projection,
    Tabset
  },
  computed: {
    ...mapGetters('config', [
      'shape',
      'model',
      'glass',
      'color',
      'furniture',
      'options',
      'inox',
      'view',
      'mirrored',
      'house',
      'black'
    ]),
    ...mapGetters('session', ['i18n']),
    activeColor() {
      let viewState;
      if (this.colorType === 'outside') {
        viewState = 'outside';
      } else if (this.colorType === 'inside') {
        viewState = 'inside';
      } else {
        if (this.house === 'outside') {
          viewState = 'outside';
        }
        if (this.house === 'inside') {
          viewState = 'inside';
        }
      }
      return this.color[viewState];
    },
    //function for inox show  now is not used
    showMolding() {
      const moldingUrl = this.inox.url?.toLowerCase();
      if (moldingUrl.includes('molding')) {
        if (moldingUrl.includes('outside')) {
          return this.house === 'outside';
        }
        if (moldingUrl.includes('inside')) {
          return this.house === 'inside';
        }
        return true;
      }
      return false;
    },
    //function for inox show  now is not used
    showInox() {
      if (!!this.inox.inox_inside && !!this.inox.inox_outside) {
        return true;
      }
      if (this.house === 'inside') {
        return !!this.inox.inox_inside;
      }
      if (this.house === 'outside') {
        return !!this.inox.inox_outside;
      }
      return false;
    },
    showBlackChecked() {
      const blackDesign = this.black;

      if (blackDesign === 'both') {
        return true;
      }

      if (this.house === 'outside') {
        return blackDesign === 'outside';
      }

      if (this.house === 'inside') {
        return blackDesign === 'inside';
      }

      return false;
    }
  },
  methods: {
    getWhiteColor() {
      axios.get(API.GET_COLORS).then(response => {
        this.white = response.data.find(
          c => c.url.toLowerCase() === 'white-pvc'
        );
        if (!this.white.id) {
          this.white = response.data.find(
            c => c.colour.toLowerCase() === '#ffffff'
          );
        }
      });
    },
    getColors() {
      const params = new URLSearchParams();
      params.append('view', this.house);

      if (this.activeTab === 0) {
        params.append('category', 'color');
        params.append('category', 'laminat');
      } else {
        params.append('category', 'aluminum');
      }

      axios
        .get(API.GET_COLORS, {
          params
        })
        .then(response => (this.items = response.data));
    },
    toggleTab(idx) {
      this.activeTab = idx;
      this.getColors();
    },
    async toggleBlack($event) {
      const {
        shape,
        model,
        glass,
        furniture,
        options,
        inox,
        view,
        mirrored
      } = this;
      const colour_outside = this.color['outside'];
      const colour_inside = this.color['inside'];

      let response;

      let setBlack = 'none';
      const checked = $event.target.checked;

      if (checked) {
        if (this.house === 'outside') {
          if (this.black === 'inside') {
            setBlack = 'both';
          } else {
            setBlack = 'outside';
          }
        } else {
          if (this.black === 'outside') {
            setBlack = 'both';
          } else {
            setBlack = 'inside';
          }
        }
      } else {
        if (this.house === 'outside') {
          if (this.black === 'both') {
            setBlack = 'inside';
          } else {
            setBlack = 'none';
          }
        } else {
          if (this.black === 'both') {
            setBlack = 'outside';
          } else {
            setBlack = 'none';
          }
        }
      }

      const moldingView = inox?.url.includes('molding')
        ? inox?.url.split('-')[1]
        : null;

      try {
        response = await axios.get(API.GET_CODE_FROM_CONFIG, {
          params: {
            shape: shape.id,
            model: model.id,
            glass: glass.id,
            colour_outside: colour_outside.id,
            colour_inside: colour_inside.id,
            furniture: furniture.id,
            options: options.id,
            inox: inox.id,
            molding: moldingView,
            view: view.id,
            mirrored: mirrored.id,
            black: setBlack
          }
        });
      } catch (e) {
        console.error(e);
      }

      if (response?.data?.code) {
        await setCode({
          code: response.data.code,
          store: this.$store,
          router: this.$router
        });

        // we need to reset inox because slot inox depends on colour type
        await axios
          .get(API.GET_CONFIG_FROM_CODE, {
            params: {
              code: response?.data?.code
            }
          })
          .then(async response => {
            if (response.data?.inox?.black) {
              await this.$store.dispatch('config/CONFIG_SET_BLACK', setBlack);
            }
          });
      }
    },
    async setColor(item, isActive) {
      if (isActive) {
        return false;
      }

      const {
        shape,
        model,
        glass,
        furniture,
        options,
        inox,
        view,
        mirrored,
        black
      } = this;

      // change furniture if color category is 2
      if (
        (furniture.id === '20' && item.category === '2') ||
        (furniture.id === '21' && item.category === '2')
      ) {
        let response = await axios.get(API.GET_FURNITURE, {
          params: { model: this.model.id }
        });
        let newFurniture = response.data.find(item => item.id === '11');
        if (newFurniture) {
          await this.$store.dispatch(
            'config/CONFIG_SET_FURNITURE',
            newFurniture
          );
        }
      }

      let response;
      let colorOutside;
      let colorInside;
      const moldingView = inox?.url.includes('molding')
        ? inox?.url.split('-')[1]
        : null;

      if (this.house === 'outside') {
        try {
          response = await axios.get(API.GET_CODE_FROM_CONFIG, {
            params: {
              shape: shape.id,
              model: model.id,
              glass: glass.id,
              colour_outside: item.id,
              colour_inside: item.id,
              furniture: furniture.id,
              options: options.id,
              inox: inox.id,
              molding: moldingView,
              view: view.id,
              mirrored: mirrored.id,
              black
            }
          });
        } catch (e) {
          console.error(e);
        }
        colorOutside = item;
        colorInside = item;
      } else {
        const colour_outside = this.color['outside'];
        try {
          response = await axios.get(API.GET_CODE_FROM_CONFIG, {
            params: {
              shape: shape.id,
              model: model.id,
              glass: glass.id,
              colour_outside: colour_outside.id,
              colour_inside: item.id,
              furniture: furniture.id,
              options: options.id,
              inox: inox.id,
              view: view.id,
              mirrored: mirrored.id,
              black
            }
          });
        } catch (e) {
          console.error(e);
        }
        colorOutside = colour_outside;
        colorInside = item;
      }

      if (response?.data?.code) {
        await setCode({
          code: response.data.code,
          store: this.$store,
          router: this.$router
        });

        // we need to reset inox because slot inox depends on colour type
        await axios
          .get(API.GET_CONFIG_FROM_CODE, {
            params: {
              code: response?.data?.code
            }
          })
          .then(async response => {
            if (response.data?.inox) {
              await this.$store.dispatch(
                'config/CONFIG_SET_INOX',
                response.data.inox
              );
            }
          });
      }

      await this.$store.dispatch(
        'config/CONFIG_SET_COLOR_OUTSIDE',
        colorOutside
      );
      await this.$store.dispatch('config/CONFIG_SET_COLOR_INSIDE', colorInside);
    }
  },
  metaInfo() {
    return {
      title: this.i18n.MENU.colour,
      meta: [
        { vmid: 'description', property: 'description', content: 'Колір' },
        { vmid: 'og:title', property: 'og:title', content: 'Колір' },
        { vmid: 'og:description', property: 'og:description', content: 'Колір' }
      ]
    };
  },
  created() {
    this.getWhiteColor();
    this.getColors();
    if (this.color.outside !== this.color.inside) {
      if (this.color.outside.colour.toLowerCase().includes('ffffff')) {
        this.colorType = 'inside';
      }
      if (this.color.inside.colour.toLowerCase().includes('ffffff')) {
        this.colorType = 'outside';
      }
    }
  }
};
</script>

<style lang="scss">
.page-width {
  padding: 30px;
}

.colors {
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;
  &__select {
    margin-top: 30px;
  }
  &__hr {
    margin: 20px 0;
    border: 1px solid #aaa;
  }
  &__item {
    flex: 1 0 33.33333%;
    max-width: 33.33333%;
    padding: 10px;
    opacity: 0.5;
    &--active {
      opacity: 1;
      cursor: pointer;
    }
    &--active:hover,
    &--current {
      .colors__bg {
        &:before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          box-sizing: border-box;
          border: 8px solid #c70552;
        }
      }
    }
    &--current {
      cursor: default;
    }
  }
  &__bg {
    display: block;
    position: relative;
    height: 0;
    padding-bottom: 100%;
    text-indent: -9999px;
    overflow: hidden;
  }
  &__inp {
    visibility: hidden;
  }
  &__title {
    color: #fff;
    padding: 10px;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    display: none;
    align-items: center;
    justify-content: center;
    text-indent: 0;
    font-size: 12px;
    line-height: 13px;
    small {
      font-size: 9px;
      line-height: 12px;
      display: block;
      margin-top: 5px;
    }
    .colors__item--active:hover &,
    .colors__item--current & {
      display: flex;
      flex-direction: column;
    }
  }
  &__empty {
    color: #ffffff;
  }
}
</style>
<style lang="scss" scoped>
.checkform {
  margin-left: 30px;
  display: flex;
  [type='checkbox']:checked,
  [type='checkbox']:not(:checked) {
    position: absolute;
    left: -9999px;
  }
  [type='checkbox']:checked + label,
  [type='checkbox']:not(:checked) + label {
    position: relative;
    padding: 4px 20px 0 40px;
    cursor: pointer;
    font: 15px/18px 'ProximaNovaRegular', sans-serif;
    color: #ffffff;
    display: flex;
  }
  [type='checkbox']:checked + label:before,
  [type='checkbox']:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 24px;
    height: 24px;
    border: 1px solid #7d8b97;
  }
  [type='checkbox']:checked + label:after,
  [type='checkbox']:not(:checked) + label:after {
    content: '';
    width: 10px;
    height: 10px;
    background: #c70552;
    position: absolute;
    top: 8px;
    left: 8px;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
  [type='checkbox']:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
}
</style>
